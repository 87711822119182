<template>
  <div v-if="authStore.token" class="nav">
    <img class="nav-logo" src="../assets/img/lendela_logo.svg" @click="reroute('application-page')">
    <div class="nav-right">
      <!-- product switcher -->
      <div v-if="appStore.products && appStore.products.length > 1 && $route.name === 'application-page'" class="current-prod-wrapper desktop-only">
        <label> <strong> {{ getButtonLabel('chooseCurrentProduct') }}: </strong> </label>
        <select v-model="productView">
          <option v-for="product in appStore.products" :key="product" :value="product">
            {{ getProductName(product) }}
          </option>
        </select>
      </div>

      <searchbar v-if="$route.name !== 'file-downloads'" class="desktop-only" />

      <appointment-drawer class="mobile-only" />
      <performance-drawer class="mobile-only" />
      <fields-list-drawer v-if="appsLoaded && currentTab !== 'closed'" class="mobile-only" />

      <!-- mobile drawer menu -->
      <div>
        <div class="mobile-menu" :class="(showMobileMenu) ? 'showMobileMenu' : ''">
          <!-- menu icon -->
          <img src="../assets/img/menu.svg" @click="showMobileMenu = true">

          <!-- drawer menu -->
          <div class="drawer-menu-container">
            <div class="hideMobileMenuTrigger" @click="showMobileMenu = false" />
            <div class="drawer-menu">
              <!-- product switcher -->
              <div v-if="appStore.products && appStore.products.length > 1 && $route.name === 'application-page'" class="current-prod-wrapper">
                <label> <strong> {{ getButtonLabel('chooseCurrentProduct') }}: </strong> </label>
                <select v-model="productView" style="margin-top:10px; width:100%">
                  <option v-for="product in appStore.products" :key="product" :value="product">
                    {{ getProductName(product) }}
                  </option>
                </select>
              </div>

              <!-- language switcher -->
              <div v-if="locale === 'hk'" class="language-switcher-wrapper" @click="switchLang((language === 'EN') ? 'CN' : 'EN')">
                Switch to {{ (language === 'EN') ? 'CN' : 'EN' }}
              </div>

              <!-- settings button -->
              <div @click="reroute('settings-page')"> {{ getButtonLabel('settingsButton') }} </div>

              <!-- logout button -->
              <div id="logout-button" @click="logout()"> {{ getButtonLabel('logoutButton') }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../js/eventBus';
import applicationServices from '../mixins/apiServices/application';
import authServices from '../mixins/apiServices/auth';
import searchbar from './searchbar';
import appointmentsDrawer from './appointmentsDrawer';
import performanceDrawer from './performanceDrawer';
import fieldsListDrawer from './fieldsListDrawer';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';
import { useAuthStore } from '../store/auth';
import { useNuxtStore } from '../store/nuxt';

export default {
  components: {
    searchbar,
    'appointment-drawer': appointmentsDrawer,
    'performance-drawer': performanceDrawer,
    'fields-list-drawer': fieldsListDrawer,
  },
  mixins: [applicationServices, authServices],
  data () {
    return {
      languages: {
        hk: ['EN', 'CN']
      },
      showMobileMenu: false,
      appStore: useAppStore(),
      authStore: useAuthStore(),
      domainStore: useDomainStore(),
      nuxtStore: useNuxtStore(),
    }
  },
  computed: {
    miscButtonLabels () {
      return this.domainStore.miscButtonLabels();
    },
    productTexts () {
      return this.domainStore.productTexts();
    },
    locale () { return this.nuxtStore.locale },
    language () { return this.nuxtStore.language },
    appsLoaded () { return this.appStore.appsLoaded },
    currentTab () { return this.appStore.currentTab },
    productView: {
      get () {
        return this.appStore.currentProduct;
      },
      async set (val) {
        EventBus.$emit('changeProduct');
        this.appStore.setAppStore('currentProduct', val);
        this.appStore.setAppStore('appNextCursor', undefined);
        const { responseCode, data: { applications, properties: { onlineLender, allowExport, userViewSettings } } } = await this.getApplicationWithPagination();
        this.appStore.setAppStore('apps', applications);
        this.appStore.setOnlineLenderFlag(onlineLender);
        this.appStore.setAllowExportFlag(allowExport);
        this.appStore.setUserViewSettings(userViewSettings);
        EventBus.$emit('changeProductDone');

        if (this.showMobileMenu) this.showMobileMenu = false;
      }
    },
  },
  methods: {
    reroute (routeName) {
      if (routeName !== this.$route.name) {
        if (this.showMobileMenu) this.showMobileMenu = false;
        return this.$router.push({ name: routeName });
      }

      return this.$router.go({ name: routeName })
    },
    switchLang (lang) {
      this.nuxtStore.setLang(lang)
      this.updateUserLanguageSettings({ language: lang });
      if (this.showMobileMenu) this.showMobileMenu = false;
    },
    goToSettings () {
      this.$router.push({ name: 'application-page' });
    },
    logout () {
      this.$router.push({ path: '/' });
      this.authStore.clearAuth();
      this.appStore.clearAppStore();
      this.nuxtStore.resetDimensions();
      this.nuxtStore.resetLocale();
      this.showMobileMenu = false;
      this.$forceUpdate();
    },
    getButtonLabel (index) {
      if (this.miscButtonLabels[index]) return this.miscButtonLabels[index].label;
      return index;
    },
    getProductName (key) {
      return (this.productTexts[key]) ? this.productTexts[key] : key;
    }
  },
}
</script>
